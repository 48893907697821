<template>
  <div>
    <b-card no-body class="mb-2">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-4">
						<div class="d-flex">
							<input
								type="search"
								class="form-control"
								placeholder="Pesquisar..."
								v-model="filters.title"
							/>
							<button @click="getData" type="button" class="btn btn-primary ml-1">
								<i v-if="!btnSearch" class="bi bi-search"></i>
								<b-spinner v-else small variant="light" />
							</button>
						</div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(actions)="data">
          <div class="form-row align-items-center justify-content-center">
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-delete-icon`"
                icon="TrashIcon"
                class="cursor-pointer cursor text-danger"
                size="16"
                @click="confirmDelete(data.item.uuid)"
              />
            </div>
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
                @click="modalEdit(data.item.uuid)"
              />
            </div>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />

      <b-modal
        id="modal-create-model-contract"
        modal-class="modal-create"
        :title="titleModal"
        centered
        hide-footer
      >
        <b-form @submit.prevent="submitCreate">
          <label for="">
            <i class="text-danger bi bi-record-circle"></i>
            Nome
          </label>
          <div class="form-group">
            <b-form-input
              v-model="item.name"
              :class="{ 'is-invalid': $v.item.name.$error }"
            />
          </div>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-model-contract')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  BSpinner,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 50,
        title: '',
      },
      uuid: null,
			btnSearch: false,
      textSubmit: "",
      submited: false,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "50",
          value: 50,
        },
        {
          title: "100",
          value: 100,
        },
      ],
      tableColumns: [
      {
          key: "name",
          label: "Nome",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-model-contract", () => {
      this.clearForm();
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Modelo de Contrato";
      this.$bvModal.show("modal-create-model-contract");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-model-contract");
  },
  methods: {
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();
      this.$store.dispatch("ModelContract/show", uuid).then((res) => {
        this.item = {
          name: res.name,
        };

        this.uuid = res.uuid;
        this.$bvModal.show("modal-create-model-contract");
      });
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("ModelContract/store", this.item)
            .then(() => {
              this.notifyDefault("success");
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-model-contract");
                this.clearForm();
              });
            })
            .catch(() => {
              this.notifyDefault("error", "Este modelo de contrato está sendo utilizado.");
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("ModelContract/update", { uuid: this.uuid, data: this.item })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-model-contract");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        name: "",
      };
    },
    async getData() {
			this.btnSearch = true;

      this.$store
        .dispatch("ModelContract/all", this.filters)
        .then((res) => {
          this.dataItems = res.data;
          this.rowsTable = res.data.length;
          this.totalRows = res.meta.total;
          this.filters.currentPage = res.meta.current_page;
        })
				.finally(() => {
					this.btnSearch = false;
				});
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("ModelContract/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          })
          .catch(() => {
            this.notifyDefault("error", "Modelo de contrato em uso.");
          })
        }
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script>
